@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Neuton:200,300,400,400i,700,800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 16px;
  font-family: 'Lato';
}

body {
  background-color: #f6f6f4;
  position: relative;
  --mw: 1200px;
}

.outer-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#wrapper {
  padding: 4rem 6rem 2rem 6rem;
  width: 100%;
  height: 100%;
  max-width: calc(100vh * 16/10);
  max-height: calc(100vw * 10/16);
  margin: 0 auto;
  display:grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2,50%);
  // grid-row-gap: 2%;
  // grid-column-gap: 1%;
  position: relative;
}

.container {
  display: none;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(20,1fr);
  font-size: 0.6rem;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid black;
  }
}

.container.visible {
  display: grid;
}

section {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
  background-color: white;
}

h2 {
  font-size: 0.9rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: black;
}


/* ~~~~~~~~~~~~~~~~~ HEADER ~~~~~~~~~~~~~~~~~ */
.home {
  display: block;
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 7rem;
  z-index: 10;
}

.home a {
  font-family: linotype-sabon, sans-serif;
  text-decoration: none;
  color: #373737;
}

.home p {
  width: 100%;
  height: auto;
  font-size: 0.8rem;
  padding-top: 0.3rem;
}

.nav__gallery {
  display: none;
}
/* ~~~~~~~~~~~~~~~~~ HEADER ~~~~~~~~~~~~~~~~~ */



/* ~~~~~~~~~~~~~~~~~ CONTROLS ~~~~~~~~~~~~~~~~~ */

.controls {
  grid-column-start: 15;
  grid-column-end: 16;
  grid-row-start: 2;
  grid-row-end: 3;
  display: none;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.button {
  padding: 0.5rem;
  -webkit-appearance: none;
  border-radius: 0;
  background-color: white;
  border: 0.5px solid silver;
  line-height: 1;
  text-transform: uppercase;
  font-family: 'Lato';
  font-size: 0.6rem;
  font-weight: 400;
  color: rgba(0,0,0,0.7);
  cursor: pointer;
}

.controls  .button {
  width: 100%;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
}

.controls  .button i {
  margin-left: 0.2rem;
}

/* ~~~~~~~~~~~~~~~~~ CONTROLS ~~~~~~~~~~~~~~~~~ */



/* ~~~~~~~~~~~~~~~~~ NAV ~~~~~~~~~~~~~~~~~ */

.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__btn {
  width: 2rem;
  height: 2rem;
  margin: 1.5rem;
  border-top: 2px solid black;
  border-left: 2px solid black;
  cursor: pointer;
  z-index: 10;
  &.hidden {
    display: none;
  }
}

.nav__btn-prev {
  transform: rotate(-45deg);
}
.nav__btn-next {
  transform: rotate(135deg);
}
.nav__btn:hover {
  color: #6b0a0a;
}

/* ~~~~~~~~~~~~~~~~~ NAV ~~~~~~~~~~~~~~~~~ */



/* ~~~~~~~~~~~~~~~~~ FOOTER ~~~~~~~~~~~~~~~~~ */
.footer {
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  font-size: 0.8rem;
  z-index: 10;
}

.footer a {
  color: black;
  text-decoration: underline;
}

/* ~~~~~~~~~~~~~~~~~ FOOTER ~~~~~~~~~~~~~~~~~ */




/* ~~~~~~~~~~~~~~~~~ EDITOR ~~~~~~~~~~~~~~~~~ */
.editor {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 15;
  z-index: 1;
  position: relative;
  border: 1rem solid white;
}

.editor__textarea {
  width: 100%;
  height:100%;
  font-family: 'Neuton';
  font-size: 1.3em;
  line-height: 1.3;
  overflow: hidden;
  user-select: none;
}

.editor__meta {
  position: absolute;
  bottom: 0.4em;
  left: -0.4em;
  transform-origin: bottom left;
  transform: rotate(-90deg);
  font-size: 0.9em;
  display: none;
  font-style: italic;
  font-weight: 300;
}

.editor__meta p {
  display: inline;
  line-height: 1;
}

.editor__meta a {
  color: black;
  border-bottom: 0.5px solid rgba(0,0,0,0.7);
  &:hover {
    font-weight: 400;
  }
}




/* ~~~~~~~~~~~~~~~~~ EDITOR ~~~~~~~~~~~~~~~~~ */



/* ~~~~~~~~~~~~~~~~~ CANVAS ~~~~~~~~~~~~~~~~~ */
.canvas {
  grid-column-start: 3;
  grid-column-end: 16;
  grid-row-start: 3;
  grid-row-end: 20;
}
.canvas canvas {
  width: 100%;
  height: 100%;
}
/* ~~~~~~~~~~~~~~~~~ CANVAS ~~~~~~~~~~~~~~~~~ */




/* ~~~~~~~~~~~~~~~~~ SINGLE ~~~~~~~~~~~~~~~~~ */

body.single {
  #wrapper {
    display:block;
  }

  section {
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.3);
  }

  .container {
    font-size: 1rem;
    cursor: auto;
    width: 100%;
    height: 100%;
    &:hover {
      border: none;
    }
  }

  .editor {
    grid-column-end: 7;
    grid-row-end: 15;
  }

  .editor {
    border: none;
  }

  .editor__textarea {
    overflow: scroll;
    padding: 1rem;
  }

  .nav__gallery {
    display: block;
  }

  .controls {
    display: flex;
  }

  .editor__meta {
    display: block;
  }
}

/* ~~~~~~~~~~~~~~~~~ SINGLE ~~~~~~~~~~~~~~~~~ */
